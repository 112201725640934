html {
    font-size: 16px;
}

a {
    text-decoration-line: none;
    text-decoration: none;
}

body {
    box-sizing: border-box;
    background-color: rgb(26, 26, 26);
    background-position: top;
    background-size:auto;
    margin-left: 5%;
    margin-right: 5%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.cardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    min-height: 100vh;
    margin: auto;
    z-index: 1;
}

.cardContainer {
    width: 100%;
    padding-bottom: 57.14%;
    position: relative;
    border-color: transparent;
    box-shadow: 0rem 0rem 1.5rem 1rem #1f1f1f;
    overflow: hidden;
    z-index: 2;
    border:0;
}

.cardContent {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    background-image: url("../public/textur.png");
    background-color: #1a1a1a;
    background-blend-mode:multiply;
    background-repeat: repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}



.whitetext {
    font-family: 'Garfist', sans-serif;
    line-height: 100%;
    font-size: 1.25rem;
    font-weight: bolder;
    color:rgb(0, 0, 0);
    margin: 0;
    text-shadow: none;
}

@media screen and (max-width: 50rem) and (max-height: 31.25rem) and (orientation: landscape) {

    .cardWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 80%;
        min-height: 100vh;
        margin: auto;
      }


}

@media screen and (min-width: 50rem) and (max-height: 31.25rem) and (orientation: landscape) {

    .cardWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 70%;
        min-height: 100vh;
        margin: auto;
      }

}

@media screen and (max-width: 40.625rem)  {
    
    .cardWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 98%;
        min-height: 100vh;
        margin: auto;
    }
      
    .cardContainer {
        width: 100%;
        padding-bottom: 157.14%;
        position: relative;
        border: 0;
        border-color: transparent;
        box-shadow: 0rem 0rem 1rem 1rem #1f1f1f;
    }

    nav > ul {
        margin: 0;
    }

    li > a {
        font-size: 1rem;
    }
}
